import React from 'react';
import { compose } from 'recompose';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
// eslint-disable-next-line
import { adalApiFetch, endpoint, authContext, adalConfig}  from './../../adalConfig';

import List from './../List';
import Header from './../Header';
import DisplayPane from '../DisplayPane';
import EditRekaModal from '../EditRekaModal';
import CategoryPane from '../CategoryPane';

import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import './style.css';
import txt from './../../assets/languages'

import ReactModal from 'react-modal';
import SubscriptionPage from '../SubscriptionPage';
//import { registerLocale } from 'react-datepicker';

//read url from adalconfig
const url = endpoint;

ReactModal.setAppElement('#root');

const applyUpdateResult = result => prevState => {
  if (result.hits.length > 0) {
        return {
        searchHits: [...prevState.searchHits, ...result.hits],
        page: result.page,
        isError: false,
        isLoading: false,
        totalJobs: result.totalJobs,
        searchCount: prevState.searchHits.length + result.hits.length
        }
  }else{
    return ({
      isError: false,
      isLoading: false,
      fetchedAll: true
    })
  }
}
const applyUpdateResultNoJobUpdate = result => prevState => {
  if (result.hits.length > 0) {
        return {
        favHits: [...prevState.favHits, ...result.hits],
        page: result.page,
        isError: false,
        isLoading: false,
      }
  }else{
    return ({
      isError: false,
      isLoading: false,
      fetchedAll: true
    })
  }
}

const applySetResult = (result) => (prevState) => (
{
  searchHits: result.hits,
  page: result.page,
  isError: false,
  isLoading: false,
  totalJobs: result.totalJobs,
  searchCount: result.hits.length
}
);
const applySetResultNoJobUpdate = (result) => (prevState) => (
{
  favHits: result.hits.reverse(),
  page: result.page,
  isError: false,
  isLoading: false,
  favCount: result.hits.length,
}
);

const applySetError = (prevState) => ({
  isError: true,
  isLoading: false,
})

const getData = (page) =>
  `${url}/getsearch?page=${page}`

const getFav = (page) =>
`${url}/getFavorites?page=${page}`

class App extends React.Component {
  constructor(props) {
    super(props);

    // load last saved search from cache/local storage
    let sf = { categories: [false,false,false,false,false,false,false,false], region: 0, searchWord:"" }
    if (localStorage.getItem("searchFilter") !== null) {
      sf = JSON.parse(localStorage.getItem("searchFilter"))
    }
    //console.log(sf)
    
    let lang = localStorage.getItem("lang")
    if (lang === null) {
      lang = "sv"
      localStorage.setItem("lang", lang)
    }
    //console.log(txt["sv"].categoryLabels)

    this.state = {
      searchHits: [],
      favHits: [],
      searchFilter: sf,
      favFilter: { categories: [false,false,false,false,false,false,false,false], region: 0, searchWord:"" },
      totalJobs: 42,
      totalFavorites: 0,
      searchCount: 0,
      favCount: 0,
      page: null,
      isLoading: false,
      isError: false,
      isLoggedIn: this.props.isLoggedIn,
      isEditing: false,
      fetchedAll: false,
      listSearch: true,
      searchMode: true,
      settingsCollapsed: true,
      hamburgerCollapsed: true,
      createNew: false,
      isAdmin: false,
      userEmail: authContext._user.userName,
      showSubscriptionPage: false,
      lang: lang,
    };

    this.input  = React.createRef();
    this.displayPane = React.createRef();
  }

  relogin = async () => {
    const api_url = adalConfig.endpoints.api
    await authContext.acquireTokenRedirect(api_url,null,null)
  }

  componentDidMount() {
    this.setState({notificationResult : "none"})
    this.input.current.value = this.state.searchFilter.searchWord;
    this.fetchStories(this.state.searchFilter.searchWord, 0, this.state.searchFilter, this.state.favFilter, true);

    this.updateFavorites();

    //is user admin?
    adalApiFetch(fetch, `${url}/isAdmin`)
      .then(response => response.json())
      .then(result => {
        if ((result.admin && result.admin === true)
          || (process.env.NODE_ENV === "development" && process.env.REACT_APP_ENV !== "prod ")
          || process.env.REACT_APP_ENV === "dev ") {
          console.log("admin access granted")
          this.setState({isAdmin: true});
        }
      })
      .catch((err) => {
        this.relogin()
        this.onSetError(err)
      });
  }


  onInitialSearch = (e) => {
    
    e.preventDefault();

    const { value } = this.input.current;
    this.setState({ fetchedAll: false });
    this.fetchStories(value, 0, this.state.searchFilter, this.state.favFilter, this.state.searchMode);
    if(this.state.searchMode){
      let sf = this.state.searchFilter;
      sf.searchWord = value;
      this.setState({searchFilter: sf})
    }else{
      let ff = this.state.favFilter;
      ff.searchWord = value;
      this.setState({favFilter: ff})
    }

    // Save the search query
    localStorage.setItem("searchFilter", JSON.stringify(this.state.searchFilter))
  }

  onPaginatedSearch = (e) =>
    this.state.fetchedAll !== true && this.fetchStories(this.input.current.value, this.state.page + 1, this.state.searchFilter, this.state.favFilter, this.state.searchMode);

  onCheckCategory = (e) => {
    const ID = e.target.getAttribute("value");
    if(this.state.searchMode){
      let sf = this.state.searchFilter;
      // Toggle category
      sf.categories[ID] = sf.categories[ID] ? false : true;
    
      this.setState({
        searchFilter: sf
      });

      this.fetchStories(this.input.current.value,0,sf,this.state.favFilter,this.state.searchMode);

      // Save the search query
      localStorage.setItem("searchFilter", JSON.stringify(this.state.searchFilter))
    }else{
      let ff = this.state.favFilter;
      ff.categories[ID] = ff.categories[ID] ? false : true;
    
      this.setState({
        favFilter: ff
      });

      this.fetchStories(this.input.current.value,0,this.state.searchFilter,ff,this.state.searchMode);
    }
  }
  

  onSetNotificationResult = (e) =>{
    e.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0))
    this.setState({notificationResult : e})
  }

  onReset = (e) => {
    this.input.current.value = '';
    this.onInitialSearch(e);
  }

  onClearFilters = (e) => {
    this.input.current.value = '';

    // Clear the search and favorites filters
    let sf = { categories: [false,false,false,false,false,false,false,false], region: 0, searchWord:"" }
    this.setState({
      searchFilter: sf,
      favFilter: sf
    });

    // Save the search query
    localStorage.setItem("searchFilter", JSON.stringify(sf))
    this.fetchStories('', 0, sf, sf, this.state.searchMode);
  }

  updateFavorites = ()=>{
    adalApiFetch(fetch, getFav(0), {
      'method' : 'POST',
      'headers' : {
        'Content-Type': 'application/json'
      },
      'body': JSON.stringify({
        query: "",//value,
        region: this.state.favFilter.region,//0,//region,
        categories: this.state.favFilter.categories,//[false,false,false,false,false,false,false,false]//categories
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({totalFavorites: result.amount, favCount: result.amount, favHits: result.hits.reverse()})
        //console.log(result)
      })
      .catch((err) => this.onSetError(err));
  }

  getNotifications = (userEmail) =>{

    adalApiFetch(fetch, `${url}/getSubscription/`, {
      'method' : 'POST',
      'headers' : {
        'Content-Type': 'application/json'
      },
      'body': JSON.stringify({
        userEmail:  userEmail
      })
    })
      .then(response => response.json())
      .then((result) => {
        this.onSetNotificationResult(result)
      })
      .catch((err) => {
        this.onSetError(err)
      });
  }

  removeNotification = (userEmail, notificationName) =>{
    adalApiFetch(fetch, `${url}/removeSubscription/`, {
      'method' : 'POST',
      'headers' : {
        'Content-Type': 'application/json'
      },
      'body': JSON.stringify({
        userEmail:  userEmail,
        notificationName: notificationName
      })
    })
      .then(response => response.json())
      .then((result) => {
        this.getNotifications(userEmail)
        return result
      })
      .catch((err) => {
        this.onSetError(err)
        return false
      });
  }

  postNotification = (userEmail, notificationName, region, filter, keywords, updateInterval) => {
     adalApiFetch(fetch, `${url}/addSubscription/`, {
      'method' : 'POST',
      'headers' : {
        'Content-Type': 'application/json'
      },
      'body': JSON.stringify({
        userEmail:  userEmail,
        notificationName: notificationName,
        region: region,
        filter: filter,
        keywords: keywords,
        updateInterval: updateInterval
      })
    })
      .then(response => response.json())
      .then((result) => {
        this.getNotifications(userEmail)
      })
      .catch((err) => {
        this.onSetError(err)
      });
     
  }

  fetchStories = (value, page, searchFilter, favFilter, search) => {

    if (search) {
      this.setState({ isLoading: true }); //Causes errror locally
    
    //adalApiFetch wraps the fetch call and adds OAuth token in the request (that token needs to be validated by backend)
    adalApiFetch(fetch, getData(page), {
      'method' : 'POST',
      'headers' : {
        'Content-Type': 'application/json'
      },
      'body': JSON.stringify({
        query: value,
        region: searchFilter.region,
        categories: searchFilter.categories
      })
    })
      .then(response => response.json())
      .then((result) => {
        this.onSetResult(result, page)})
      .catch((err) => {
        this.onSetError(err)
      });

    }else{
      this.setState({ isLoading: true });
      //adalApiFetch wraps the fetch call and adds OAuth token in the request (that token needs to be validated by backend)
      adalApiFetch(fetch, getFav(page), {
        'method' : 'POST',
        'headers' : {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
          query: value,
          region: favFilter.region,
          categories: favFilter.categories
        })
      })
        .then(response => response.json())
        .then(result => {
          this.onSetResult(result, page)})
        .catch((err) => this.onSetError(err));
    }
  }

  reFetch = () => {
    this.setState({ fetchedAll: false });
    this.fetchStories(this.input.current.value,0, this.state.searchFilter, this.state.favFilter, this.state.searchMode);
  }

  onSetError = (err) => {
    this.setState(applySetError);
  }

  //if first page, set the result else update and append result to state
  onSetResult = (result, page) => 
 { 
    if(page === 0){
      this.state.searchMode ? this.setState(applySetResult(result)) : this.setState(applySetResultNoJobUpdate(result)); 
    }
    else{
       this.state.searchMode ? this.setState(applyUpdateResult(result)) : this.setState(applyUpdateResultNoJobUpdate(result)) ;
    }
 }    
    
  onSetRegion = (e) => {
    this.setState((prevState) => {
      if(this.state.searchMode){
        let sf = prevState.searchFilter;
        let val = sf.region;
        if (val <= 2) {
          val +=1;
        }else{
          val = 0;
        }
        sf.region = parseInt(val)
        this.fetchStories(this.input.current.value,0,sf,this.state.favFilter,this.state.searchMode);

        // Save the search query
        localStorage.setItem("searchFilter", JSON.stringify(this.state.searchFilter))

        return {'searchFilter' :sf}
      }else{
        let ff = prevState.favFilter;
        let val = ff.region;
        if (val <= 2) {
          val +=1;
        }else{
          val = 0;
        }
        ff.region = parseInt(val);
        this.fetchStories(this.input.current.value,0,this.state.searchFilter,ff,this.state.searchMode);
        return {'favFilter' :ff}
      }

    });
  }

  onShareFavourites = () => {
    let emailBody = txt[this.state.lang].emailBody

    for (let i in this.state.favHits) {
      let fav = this.state.favHits[i];
      //console.log(fav)
      
      emailBody += `\n\n${fav.id}: ${fav.title}\nhttps://uppdragsportalen.essiq.se/id/${fav.key}`;

      // mailto links cannot handle longer strings
      if (encodeURIComponent(emailBody.length) > 1800) {
        //console.log(i)
        emailBody += "\n\n" + txt[this.state.lang].emailBodyPartial + "\n"
        break
      }
    }
    //console.log(encodeURIComponent(emailBody))
    window.open(`mailto:?subject=${txt[this.state.lang].emailSubject}&body=${encodeURIComponent(emailBody)}`, "_blank");
  }
  
  onRemoveSeen = (id) => {
    localStorage.removeItem(id)

    // Update list (setting a state triggers update)
    this.setState({lang: this.state.lang})
  }

  onSwitchLanguage = () => {
    let language = this.state.lang === "sv" ? "en" : "sv";
    this.setState({ lang: language });
    localStorage.setItem("lang", language)
  }

  onSetSearchList(v) {
    this.setState({'listSearch': v})
  }

  onScrollTop() {
    window.scrollTo(0,0); 
  }

  onToggleSettings = () => {
    this.setState({settingsCollapsed: !this.state.settingsCollapsed})
  }

  onToggleHamburger = () => {
    this.setState({hamburgerCollapsed: !this.state.hamburgerCollapsed})
  }

  onExitDropdowns = () => {
    this.setState({
      settingsCollapsed: true,
      hamburgerCollapsed: true,
    })
  }

  onSetMode = (mode) => {
    this.setState({searchMode: mode, fetchedAll: false});
    let value = mode? this.state.searchFilter.searchWord:this.state.favFilter.searchWord;
    this.input.current.value = value;
    //this.fetchStories(value,0,this.state.searchFilter,this.state.favFilter,mode);
  }

  onHandleFav = () => {
    if (!this.state.searchMode) {
      this.reFetch();
    }
    this.updateFavorites();
  }

  onShowSubscriptionPage = () => {
    this.setState({showSubscriptionPage: true})
  }
  onSubscriptionPageClose = () => {
    this.setState({showSubscriptionPage: false})
  }

  render() {
    return (
      <Router>
        <Route path='/' render={({ match, props, history }) => 
          <section>
          <EditRekaModal
            isOpen={this.state.isEditing}
            onRequestClose={() => { this.setState({ isEditing: false });this.displayPane.current.refetch();}}
            id={this.state.id}
            history={history}
            createNew={this.state.createNew}
          />
          { this.state.showSubscriptionPage &&
          <SubscriptionPage
                userEmail = {this.state.userEmail}  
                onClose = {this.onSubscriptionPageClose}
                onSubscribeBtnClicked = {this.postNotification}
                onRemoveBtnClicked = {this.removeNotification}
                getNotifications = {this.getNotifications}
                notificationResult = {this.state.notificationResult}
                lang={this.state.lang}
          />}
            <Header
              onInitialSearch={e => this.onInitialSearch(e)}
              isLoading={this.state.isLoading}
              isAdmin={this.state.isAdmin}
              userEmail = {this.state.userEmail}  

              onReset={e => this.onReset(e)}
              onClearFilters={e => this.onClearFilters(e)}
              input={this.input}
              loggedIn={this.props.loggedIn} 
              region={this.state.searchMode? this.state.searchFilter.region:this.state.favFilter.region}
              onSetRegion={(e) => this.onSetRegion(e)}
              onAuth={this.props.onAuth}
              onCollapse={(e) => this.onToggleSettings(e)}
              onShareFavourites={(e) => this.onShareFavourites(e)}
              onSwitchLanguage={(e) => this.onSwitchLanguage(e)}
              collapsed={this.state.settingsCollapsed}
              onHamburgerCollapse={(e) => this.onToggleHamburger(e)}
              hamburgerDown={this.state.hamburgerCollapsed}
              history={history}
              onEdit={(id) => this.setState({ isEditing : true, id: false, createNew: true})}
              onSubscribe = {e => this.onShowSubscriptionPage(e)}
              lang={this.state.lang}
            />
            <Container className="mainContainer" fluid={true}>
              <CategoryPane
                boxes={this.state.searchMode? this.state.searchFilter.categories:this.state.favFilter.categories}
                onCheckCategory={e => this.onCheckCategory(e)}
                searchMode={this.state.searchMode}
                lang={this.state.lang}
              />
                <Switch {...props}>
                  <Route
                    path="/"
                    exact
                    render={({ match }) => (
                    <RekaList
                      searchMode={this.state.searchMode}
                      searchCount={this.state.searchCount}
                      favCount={this.state.favCount}
                      totalJobs={this.state.totalJobs}
                      totalFavorites={this.state.totalFavorites}
                      onSetMode={this.onSetMode}
                      list={this.state.searchMode? this.state.searchHits:this.state.favHits}
                      page={this.state.page}
                      onPaginatedSearch={this.onPaginatedSearch}
                      isError={this.state.isError}
                      isLoading={this.state.isLoading}
                      match={match}
                      ref={this.scrollList}
                      onHandleFav={() => this.onHandleFav()}
                      displayPane={(key) => {
                        this.onScrollTop();
                        this.displayPane.current.refetch(key)
                      }}
                      lang={this.state.lang}
                    />)}/>
                  <Route
                    path="/id/:key"
                    render={({ match }) => (
                    <RekaList
                      searchMode={this.state.searchMode}
                      searchCount={this.state.searchCount}
                      favCount={this.state.favCount}
                      totalJobs={this.state.totalJobs}
                      totalFavorites={this.state.totalFavorites}
                      onSetMode={this.onSetMode}
                      list={this.state.searchMode? this.state.searchHits:this.state.favHits}
                      page={this.state.page}
                      onPaginatedSearch={this.onPaginatedSearch}
                      isError={this.state.isError}
                      isLoading={this.state.isLoading}
                      match={match}
                      ref={this.scrollList}
                      onHandleFav={() => this.onHandleFav()}
                      displayPane={(key) => { 
                        this.onScrollTop();
                        this.displayPane.current.refetch(key)
                      }}
                      lang={this.state.lang}
                    />)}/>

                </Switch>
                <Switch>
                  <Route 
                    path="/"
                    exact
                    render={({match}) => (
                      <DisplayPane
                        match={match}
                        ref={this.displayPane}
                        lang={this.state.lang}
                      />
                    )}
                  />
                  <Route
                    path="/id/:key"
                    render={({ match }) => (
                      <DisplayPane
                        match={match}
                        isAdmin={this.state.isAdmin}
                        id={match.params.key}
                        ref={this.displayPane}
                        onRemoveSeen={this.onRemoveSeen}
                        onHandleFav={this.onHandleFav}
                        onEdit={() => this.setState({ isEditing : true, id: match.params.key, createNew: false})}
                        lang={this.state.lang}
                      />
                    )}
                  />
                </Switch>
            </Container>
            </section>
          }/>
      </Router>
    );
  }
}


const withLoading = conditionFn => (Component) => (props) =>
  <div className="loadingWrapper">
    <Component {...props} />

    <div className="interactions d-flex justify-content-center align-items-center w-100">
      {
        conditionFn(props) && 
        <Spinner animation="border" role="status" className="fixed-loading">
          <span className="sr-only">{txt[props.lang].loading}</span>
        </Spinner>
      }
    </div>
  </div>

const withPaginated = conditionFn => (Component) => (props) =>
  <div className="List">
    <Component {...props} />

    <div className="interactions">
    {
      conditionFn(props) && 
      <div>
        <div>
          {txt[props.lang].failedFetch}
        </div>
        <button
          type="button"
          onClick={props.onPaginatedSearch}
        >
          {txt[props.lang].tryAgain}
        </button>
      </div>
    }
    </div>
  </div>

//had to make this wrapper component stateful because the scroll event listener
const withInfiniteScroll = conditionFn => (Component) =>
  class WithInfiniteScroll extends React.Component {
    componentDidMount() {
      document.getElementsByClassName('listContainer')[0].addEventListener('scroll', this.onScroll, false);
    }
  
    componentWillUnmount() {
      document.getElementsByClassName('listContainer')[0].removeEventListener('scroll', this.onScroll, false);
    }
  
    onScroll = (e) => {

      conditionFn(this.props) && this.props.onPaginatedSearch();
    }
  
    render() {
      return <Component {...this.props} />;
    }
  }
  
const paginatedCondition = props =>
  props.page !== null && !props.isLoading && props.isError;

const infiniteScrollCondition = props =>
  (document.getElementsByClassName('listContainer')[0].scrollTop/document.getElementsByClassName('listContainer')[0].scrollHeight) >= 0.75
  && props.list.length
  && !props.isLoading
  && !props.isError;

const loadingCondition = props =>
  props.isLoading;


//HOC to setup a list-component composed of desired features
const RekaList = compose(
  withPaginated(paginatedCondition),
  withInfiniteScroll(infiniteScrollCondition),
  withLoading(loadingCondition),
)(List);

export default App;